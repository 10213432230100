<template>
  <div class="PinMap" :class="'PinMap'+_uid">
    <div class="PinMap--List">
      <div v-if="positivePins.length > 0" class="PinMap--Types">
        <h4>{{negativePins.length <= 0  ? "Puntos" : 'Puntos positivos' }}</h4>
          <div  class="PinMap--Elements">
            <Pin
              v-for="(pin, index) in positivePins"
              :id="'Pin-'+_uid+'-'+pin.index"
              :class="'Pin'+_uid"
              :key="pin.identifier"
              :title="pin.title"
              :type=" negativePins.length <= 0  ? primaryColor : pin.type"
              :positionx="pin.positionx"
              :positiony="pin.positiony"
              :dragging="pin.dragging"
              :added="pin.added"
              :data-index="pin.index"/>
          </div>
      </div>
      <div  v-if="negativePins.length > 0 && positivePins.length > 0" class="PinMap--Separator"></div>
      <div v-if="negativePins.length > 0" class="PinMap--Types">
        <h4>{{positivePins.length <= 0  ? "Puntos" : 'Puntos negativos' }}</h4>
        <div class="PinMap--Elements">
          <Pin
            v-for="(pin, index) in negativePins"
            :id="'Pin-'+_uid+'-'+pin.index"
            :class="'Pin'+_uid"
            :key="pin.identifier"
            :title="pin.title"
            :type="positivePins.length <= 0  ? primaryColor : pin.type"
            :positionx="pin.positionx"
            :positiony="pin.positiony"
            :dragging="pin.dragging"
            :added="pin.added"
            :data-index="pin.index"/>
        </div>
      </div>
    </div>
    <div class="PinMap__Dropzone" :class="'PinMap__Dropzone'+_uid">
      <img ref="heatMapImage" draggable="false" :src="image" @load="handleImageLoaded" />
      <div class="PinMap__DropzoneBg">

      </div>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs';
import Pin from '../Pin';
import { getCurrentInstance } from 'vue';
import { mapGetters } from 'vuex'

export default {
  name:'newPinMap',
  components: {
    Pin,
  },

  props:{
    image:String,
    pins:Array,
  },

  computed:{
    ...mapGetters([
      'theme',
    ]),
    primaryColor() {
      return this.theme.mainColor
    },
    positivePins(){
      return this.pins.map(function (p,i) {
        p.index = i
        return p
      }).filter(function (p) { return p.type == 'GREEN' });
    },
    negativePins(){
      return this.pins.map(function (p,i) {
        p.index = i
        return p
      }).filter(function (p) { return p.type == 'RED' });
    }
  },

  data() {
    return {
      imageLoaded: false,
      stageConfig: {
        width: null,
        height: null,
      },
      _uid:getCurrentInstance().uid
    };
  },

  mounted() {
    document.getElementsByClassName(this.$parent.containerClassName)[0].onmousemove = this.onmousemove // fix pin top position on scroll
    // window.addEventListener('resize', () => {
    //   this.reloadHeatMap()
    // });
    this.initDragAndDrop();
    for(let i in this.pins){
      var childPos = $('#Pin-'+this._uid+'-'+i).offset();
      var parentPos = $('.PinMap'+this._uid).offset();
      // calculate percent for saving answers
      this.pins[i].initialPosition = childPos.top - parentPos.top
      this.pins[i].initialPosition2 = childPos.left - parentPos.left
    }
  },

  methods: {
    reloadHeatMap(){
      if(this.$refs.heatMapImage.height != undefined){
        this.stageConfig.width = this.$refs.heatMapImage.width;
        this.stageConfig.height = this.$refs.heatMapImage.height;
        let index = 0
        for(let p of this.pins){
          if(p.added){
            let positiony = (this.stageConfig.height * p.y) / 100 + p.initialPosition - 15
            let positionx = (this.stageConfig.width * p.x) / 100 - p.initialPosition2 - 15
            p.positiony = positiony
            p.positionx = positionx
            document.getElementById('Pin-'+this._uid+'-'+index).setAttribute('data-y',positiony)
            document.getElementById('Pin-'+this._uid+'-'+index).setAttribute('data-x',positionx)
          }
          index++
        }
      }
    },
    onmousemove(event){
      let index = 0
      for(let p of this.pins){
        if(p.dragging){
          let y = event.pageY - $('.PinMap__Dropzone'+this._uid).offset().top + $('.PinMap__Dropzone'+this._uid).scrollTop();
          let positiony = event.pageY - $('.PinMap'+this._uid).offset().top + $('.PinMap'+this._uid).scrollTop() - p.initialPosition
          p.positiony = positiony
          p.y = y * 100 / this.stageConfig.height
          document.getElementById('Pin-'+this._uid+'-'+index).setAttribute('data-y',positiony)
        }
        index++
      }
    },
    handleImageLoaded(e) {
      this.stageConfig.width = e.target.width;
      this.stageConfig.height = e.target.height;
      this.imageLoaded = true;
    },
    initDragAndDrop(){
      let vm = this
      interact('.PinMap__Dropzone'+this._uid).dropzone({
        accept: '.Pin'+vm._uid,
        overlap: 0.1,
        ondragenter: function (event) {
          const pinIndex = event.relatedTarget.getAttribute('data-index');
          const pin = vm.pins[pinIndex];
          pin.added = true
          vm.$emit('send-answers')
          event.target.classList.add('drop-active')
        },
        ondragleave: function (event) {
          const pinIndex = event.relatedTarget.getAttribute('data-index');
          const pin = vm.pins[pinIndex];
          pin.added = false
          vm.$emit('send-answers')
          event.target.classList.remove('drop-active')
        },
        ondrop: function (event) {
          event.target.classList.remove('drop-active')
          vm.$emit('send-answers')
        }
      });

      interact('.Pin'+this._uid).draggable({
        autoScroll: true,
        inertia: true,
        modifiers: [
          interact.modifiers.restrict({
            restriction: '.PinMap'+this._uid,
            endOnly: true,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
          }),
        ],
        onmove: this.dragMoveListener,
        onend: function(event){
          const target = event.target
          const pinIndex = target.getAttribute('data-index');
          const pin = vm.pins[pinIndex];
          pin.dragging = false
          if(!pin.added){
            target.setAttribute('data-x', 0)
            target.setAttribute('data-y', 0)
            pin.positionx = 0
            pin.positiony = 0
            pin.y = null
            pin.x = null
            vm.$emit('send-answers')
          }
        }
      });
    },
    dragMoveListener(event) {
      var target = event.target
      var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
      var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
      const pinIndex = target.getAttribute('data-index');
      const pin = this.pins[pinIndex];
      pin.dragging = true
      pin.positionx = x
      pin.positiony = y
      var childPos = $('#Pin-'+this._uid+'-'+pinIndex).offset();
      var parentPos = $('.PinMap__Dropzone'+this._uid).offset();
      // calculate percent for saving answers
      pin.y = ((childPos.top - parentPos.top + 25) * 100) / this.stageConfig.height
      pin.x = ((childPos.left - parentPos.left + 15)*100) / this.stageConfig.width
    },
  },
}
</script>

<style scoped lang="scss">
.PinMap {
  max-width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  .drop-active{
    .PinMap__DropzoneBg{
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(87, 233, 114, 0.7);
      display: block;
      top: 0;
      left: 0;
    }
  }
  &__Dropzone{
    border: 1px solid lightgray;
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }
  &__Dropzone img{
    max-width: 100%;
  }
  &--List{
    padding: 10px;
    border: 1px solid lightgray;
    display: flex;
    justify-content: space-around;
    h4{
    text-align: center;
      margin-bottom: 5px;
      width: 100%;
    }
  }
  &--Elements{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  &--Separator{
    width: 0.5px;
    height: auto;
    background: lightgray;
  }
}
.primaryColor{
    color:var(--primary-color)
}
</style>
