<template lang="html">
  <div class="MultiText">
    <div class="MultiText__Texts" v-for="i in question.question.textNum">
      <p v-if="question.question.textDescriptions">{{question.question.textDescriptions[i-1]}}</p>
      <input type="text"
        :id="'inputEl'+question.question.order+'-'+i"
        :name="'answer_'+question.question.order"
        :disabled="notResponse"
        autocomplete="off"
        :placeholder="$parent.translationMethod('Imput_textanswer')"
        v-model="answers[i-1]">
    </div>
    <div class="MultiText__NotResponse" v-if="question.question.notResponse">
        <div>
          <input type="checkbox" :id="'answer_'+question.question.order" :name="'answer_'+question.question.order" v-on:change="addAnswer()" v-model="notResponse">
          <label :for="'answer_'+question.question.order">
            <span>
            </span>
            <i class="fa fa-check"></i>
            {{question.question.notResponseText}}
          </label>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiText',

  props: {
    question:{
      type: Object,
      default: null
    },
  },

  data() {
    return {
      answers:[],
      notResponse:false
    }
  },
  methods:{
    addAnswer(){
      let answerValid = false
      let answer = {
        multipleTexts:this.answers,
      }
      for (var i = 0; i < this.question.question.textNum; i++) {
        if (this.answers[i] != undefined && this.answers[i].length > 0 && (/\S/.test(this.answers[i]))) {
          answerValid = true
        }
        else if(this.question.question.textsRequired){
          answerValid = false
          break;
        }
      }
      if (this.notResponse) {
        answer.multipleTexts = [this.question.question.notResponseText]
        answer.nsnc = true
        answerValid = true
      }
      if (this.question.question.textEmpty){
        answerValid = true
      }
      this.$emit('send-answers', {answer:answer,question:this.question, answerValid:answerValid})
    },
  },
  async mounted(){
    if (this.question.answer) {
      this.answers = this.question.answer.multipleTexts
      if (this.answers[0] == this.question.question.notResponseText) this.notResponse = true
      this.addAnswer()
    }
    let vm = this
    for(var i = 0; i < this.question.question.textNum; i++){
      $('#inputEl'+vm.question.question.order+'-'+(i+1)).on('input', function(e){
         vm.addAnswer()
       });
    }
    if(this.question.question.textEmpty){
      this.$emit('send-answers', {answer:{multipleTexts:[]},question:this.question, answerValid:true})
    }
  }
}
</script>

<style lang="scss" scoped>
.MultiText{
  &__Texts{
    margin: 10px 0;
    input{
      border-radius: 5px;
      height:100%;
      color: #a6a6a6;
      width: 100%;
      border:1px solid #dfdfdf;
      outline:none;
      background: white;
      padding: 10px;
      &::placeholder{
        color: #cdcdcd;
      }
    }
  }
  &__NotResponse{
    div{
      width: 100%;
      display: flex;
      input {
        display: none;
        &:checked + label{
          color: var(--main-color);
          font-weight: 700;
          span{
            display:none;
          }
          i{
            display:block;
          }
        }
      }
      label{
        display: flex;
        align-items: center;
        font-weight: 500;
        cursor: pointer;
        i{
          display: none;
          font-size:25px;
          height: 26px;
          margin-right: 8px;
          background: linear-gradient(to right,var(--main-color) , var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        span{
          display: flex;
          width: 20px;
          min-width: 20px;
          height: 20px;
          margin: 3px 10px 3px 3px;
          padding:4px;
          vertical-align: middle;
          border: 1px solid #aeaeae;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
